
const settings =
       {
           parallax: {
               title: {
                   name: "DaGaNi Racing",
                   class: "name-color text-h1 font-weight-thin mb-4",
                   color: 'color:#FFFFF'
               },
               caption: {
                   text: `Our track day experiences are the ultimate thrill for anyone with a need for speed and a passion for powerful cars. These driving days offer you the chance to get behind the wheel of sensational race cars. Some of our packages include passenger laps with an experienced driver to show you how it’s done.`,
                   class: "caption-color text-h6 text-center font-weight-thin mb-4",
                   color: 'color:#FFFFF'
               },
               background:  "https://elthas.blob.core.windows.net/daganipublic/18-07-22_15-35_03.jpg",
               parallax_height: 2592,
           },
            youtubeVideoid: 'OH3yX_ina-U',
            faq: {
                 title: "Frequently asked questions about renting a DaGaNi Race car",
                 questions: [
                       {question: "CAN MY TRACK TIME BE EXTENDED?",                       answer: `For driver safety the track time is split up in multiple 20min sessions, with pauses in between. Depending on the driver feedback and availability additional session can be purchased € 120 per session. This is a cash only option as we are already on the track`},
                       {question: "WHAT HAPPENS WHEN I DAMAGE THE TRACK CAR?",            answer: `The driver is liable for any damage caused to the car, the driver is responsible for the costs of repair or replacement of the car, the maximum amount for a total loss is € 40,000 incl.VAT.  Technical damage to the car due to incorrect or careless use is always at your own expense.`},
                       {question: "WHY IS A SECURITY DEPOSIT REQUIRED?",                  answer: `In the event of damage to the rental car, the owner (DaGaNi Racing) will use this security deposit to defray the costs of necessary repairs or replacement. All drivers will be required to provide a security deposit at least two days prior to the event in the amount of € 7.500. If the cost for repair or replacement of damage to the car exceeds the amount of the security deposit, the renter will be responsible for payment to the owner the remaining balance of this cost.`},
                       {question: "CAN I GET AN INSURANCE?",                              answer: `Yes, we do provide insurances as optional extra on the booking page, this is offered by an external company at €350 per driver with a €2500 insurance exemption "eigen risico" as security deposit`},
                       {question: "WHAT TRACKS DOES THIS APPLY TO?",                      answer: `All the information above as well as the indicative prices are based on track days on Circuit Zolder, Mettet, Zandvoort and Spa-Francorchamps. If you want to rent our cars on any other tracks, please get in touch.`},
                       {question: "WHAT IS INCLUDED IN THE TRACK CAR RENTAL FEE?",        answer: `All DaGaNi rental cars include fuel, tyres and track fees. The track cars are delivered to a location of your choice.`},
                       {question: "CAN I BRING A FRIEND?",                                answer: `Yes! We do give a discount for drivers sharing the car with a friend, each will in turn drive 20min sessions. However, drivers cannot take their friends as passengers for safety reasons.`},
                       {question: "WHY IS THERE A PRICE RANGE FOR THE TRACK DAY FEE?",    answer: `This is track dependent, for example Francorchamps is often the most expensive and Mettet is most of the time the least expensive.`},
                       {question: "I'M A ROOKIE DRIVER DO YOU HAVE TRACK RECOMMENDATIONS?", answer: `Mettet is the most forgiving track with space in case of an error, Francorchamps as F1 circuit has also plenty of space, the high speed corners require some experience, at Zolder the barriers are in close proximity of the track meaning that there is less room for driver errors.`},
                       {question: "ARE LUNCH, PITBOX, HELMETS,…AUTOMATICALY INCLUDED?",  answer: `Included are: track day fee, private coaching, transport, rental car, fuel, tyres. Other services are optional and are not included in the indicative pricing as mentioned.`},
                 ]

            },
            termsandconditions: {
               title: 'Driving Experiences',
               terms: [
                       {
                           question: "Our Commitment",
                           answer: `DaGaNi Racing is committed to ensuring that all its customers enjoy driving experiences of the highest quality which are safe, exciting and value for money.
                                    Whether a novice driver or experienced professional, we want you to feel welcome at each of our venues and to enjoy expert tuition from licensed instructors in the best vehicles and pleasant surroundings.
                                    We know how much our customers look forward to their driving experiences and our terms of business have been carefully written to ensure that there are no misunderstandings that could detract from your enjoyment.`},

                       {
                           question: "Your Commitment",
                           answer: `We ask that you arrive early and register for your activity at least 30 minutes before the scheduled start time. We cannot permit anyone to participate in an experience without attending the preceding drivers briefing, so it is essential that you arrive on time. No alcohol can be consumed prior to the experiences, and we reserve the right to refuse an activity to persons we suspect to be under the influence of alcohol and/or drugs.
                                    Once you have booked your chosen date, we reserve your car, instructor and circuit time. If you then choose to cancel, you will be in breach of contract and we reserve the right to retain all monies paid.
                                    If it becomes necessary for us to make a change to the vehicles used, the activity location or the course details, we will use our reasonable efforts to contact you to advise you of any change and may offer you an alternative activity of an equivalent value.
                                    All our experiences requires the wearing of a crash helmet, this is mandatory and there are no exceptions.
                                    NO ANIMALS may be brought onto the premises - with the exception of Registered Guide Dogs.`
                       },
                       {
                           question: "Changes of Plan",
                           answer: `If the nominated driver is unable to attend and you wish to send a replacement driver to take the reserved driving experience, we can accept substitutes up to the time of signing on, provided we have written authorisation from either the purchaser or the original driver, and the nominated substitute complies with all necessary criteria.`
                       },

                       {
                           question: "Driving Licences",
                           answer: `All drivers, must hold a full and current driving licence to take part in any experience. Drivers must produce their actual driving licence when signing on for their course. Provisional licences or photocopies of licences are not acceptable. Where there is mention of the length of time that a licence must be held by the driver, you may purchase a Gift Voucher before this period, but the term must have elapsed before an experience can be taken.`
                       },

                      {
                          question: "Description",
                          answer: `Where the duration of an experience is mentioned on our website, this is an approximate time and is given as a guide only. Unless specifically stated otherwise you will normally take your Driving Experience with other participants and there may be some waiting time involved.`
                      },

                     {
                         question: "Insurance",
                         answer: `The very nature of our Driving Experiences means that some personal risk may be involved in taking part in the activities we offer and you should be aware of this when confirming booking. We require the driver's signature on a registration form before participation is allowed. Please note that some medical conditions preclude certain drivers from taking part.`
                     },

                     {
                         question: "The Weather",
                         answer: `All our driving experiences are offered subject to availability of dates, vehicles, facilities and the weather and there can be no guarantee that a particular date will not be withdrawn due to extreme adverse weather conditions.
                                    If we are unable to run your driving experience due to extreme adverse weather conditions or other events beyond our control, you will be able to re book your Driving Experience. `
                     },

                    {
                      question: "Changes",
                      answer: `We reserve the right to make changes to the advertised prices, but this will not affect you if you have already booked and paid for an activity. All information and prices are correct at the time of going to press. Whilst it is our intention to offer experiences as described, some revisions may be necessary. `
                    },


                 ]
            },
            teambuilding: {
               title: 'Team Building & Group Arrangements',
                picture:  "https://elthas.blob.core.windows.net/daganipublic/DSC_5044.JPG",
                terms: [
                    {
                        question: "Team Building & Group Arrangements",
                        answers: [
                            `Celebrate, blow off steam, or entertain clients on the race track!`,
                            `The event begins with a half-hour class on Vehicle Dynamics by an instructor to prepare you for the challenges faced on track. Then, driving students are ready to suit up with race shoes and helmets we provide and take the wheel of their very own race car for a once-in-a-lifetime adventure! `,
                            `Still need a confidence boost before taking the wheel?! Switch places with one of our coaches and they will show you “how it’s done” for some professional-grade laps!`,
                        ]
                    },

                           {
                               question: "What can we offer?",
                               answers: [
                                   `• The opportunity to learn and work on your driving skills.`,

                                        `• The necessary catering and refreshments for the breaks.`,

                                        `• An original fun way to socialize on the track in a coronaproof manner.`,

                                        `• A unique experience suitable for everyone, both young and old, experienced pilots or complete beginners.`,

                                        `• An event at a circuit of your choice.`,
                               ]
                           },
                           {
                               question: "How much does an event on the circuit cost?",
                               answers: [
                                   `The price of our group events varies according to the chosen track, date, the amount of cars, amount of drivers and the amount of coaches, €1800 (excl. VAT) is an indicative price for a full day with your group with guidance of one of our coaches. For smaller groups, a hall day can be booked, at around €1100 (excl. VAT)`

                               ]
                           },
                           {
                               question: "Customization",
                               answers: [
                                   `Our main strength in organizing events is the provision of customization. We understand that every company or group has different wishes. You can choose from various arrangements and suggestions, but if you have specific wishes, we will realize them for you. For example, your company logo on our race cars during the event (A4 or A3 size) or even filming the event, comprising on board footage of your ride is possible! Feel free to contact us at any time (Dutch, French, English or German).`

                               ]},
                          {
                               question: "Contact",
                               answers: [
                                   ` info@dagani-racing.be `

                               ]},
                       
                 ]
            },
       }


export default settings;

