var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-parallax',{attrs:{"src":_vm.settings.parallax.background,"dark":"false","height":_vm.settings.parallax.parallax_height}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"md":"5"}},[_c('v-img',{attrs:{"src":"https://elthas.blob.core.windows.net/daganipublic/logo-white.png","height":"100","contain":""}}),_c('br'),_c('p',{class:this.settings.parallax.title.class,style:(this.settings.parallax.title.color)},[_vm._v(" "+_vm._s(this.settings.parallax.title.name))]),_c('br'),_c('p',{class:this.settings.parallax.caption.class,style:(this.settings.parallax.caption.color)},[_vm._v(_vm._s(this.settings.parallax.caption.text))])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","md":"8"}},[_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_vm._l((_vm.cars),function(car){return _c('v-col',{key:car.name,attrs:{"md":car.flex,"xs":"12"}},[_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":car.picture,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"300px"}},[_c('v-card-title',{domProps:{"textContent":_vm._s(car.name)}})],1),_c('v-card-actions',[_c('v-spacer'),_c('xexperience',{attrs:{"car":car}})],1)],1)],1)}),_c('v-col',{attrs:{"md":"8","xs":"12"}},[_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.settings.teambuilding.picture,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"300px"}},[_c('v-card-title',[_vm._v(_vm._s(this.settings.teambuilding.title))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-dialog',{ref:"button",attrs:{"max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("Details")])]}}]),model:{value:(_vm.teambuilding_dialog),callback:function ($$v) {_vm.teambuilding_dialog=$$v},expression:"teambuilding_dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-img',{attrs:{"height":"250","src":_vm.settings.teambuilding.picture}}),_c('v-card-text',[_vm._l((_vm.settings.teambuilding.terms),function(q){return [_c('p',{staticClass:"font-weight-black"},[_vm._v(_vm._s(q.question))]),_vm._l((q.answers),function(a){return [_vm._v(" "+_vm._s(a)+" "),_c('br'),_c('br')]})]})],2)],1)],1)],1)],1)],1)],2)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('youtube',{attrs:{"video-id":_vm.settings.youtubeVideoid,"player-vars":{start: 0, autoplay: 0}}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('v-spacer'),_c('v-list',{attrs:{"dense":"","rounded":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("info@dagani-racing.be")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("+32 477 56 47 44")])],1)],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Photo Gallery ")])]}}]),model:{value:(_vm.gallery_dialog),callback:function ($$v) {_vm.gallery_dialog=$$v},expression:"gallery_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.gallery_dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("DaGaNi Racing Gallery")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('xgallery')],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Frequently Asked Questions ")])]}}]),model:{value:(_vm.faq_dialog),callback:function ($$v) {_vm.faq_dialog=$$v},expression:"faq_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.faq_dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Frequently Asked Questions")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('xfaq')],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Terms and Conditions ")])]}}]),model:{value:(_vm.taq_dialog),callback:function ($$v) {_vm.taq_dialog=$$v},expression:"taq_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.taq_dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Terms and Conditions")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('xtermsandconditions')],1)],1),_c('v-spacer')],1)],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }