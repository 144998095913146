
const cars = [

       { flex: 12, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery11.JPG" },
       { flex: 10, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery16.jpg" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery17.JPG" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery8.JPG" },
       { flex: 10, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery3.jpg" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery7.JPG" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery6.JPG" },
       { flex: 10, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery5.jpg" },
       { flex: 11, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery9.JPG" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery12.JPG" },
       { flex: 6, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery15.jpg" },
       { flex: 9, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery14.JPG" },
       { flex: 12, picture: "https://elthas.blob.core.windows.net/daganipublic/Gallery4.jpg" },
   
      
     
]

export default cars;
