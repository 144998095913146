<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
      <v-dialog v-model="dialog" max-width="1200px" ref="button">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on" @click="get_details">Details</v-btn>
        </template>
        <v-card flat>
            <v-img
              height="250"
              :src="car.picture"
            ></v-img>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
            >
              <v-tab
                v-for="experience in car.experiences"
                :key="experience.title"
              >
                {{ experience.title }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="experience in car.experiences"
                :key="experience.title"
              >
                <v-card
                >
                  <v-card-title>{{ car.name }}</v-card-title>
                  <v-card-text>
                    <v-row
                      align="center"
                      class="mx-0"
                    >
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                      ></v-rating>
                      <div class="grey--text ms-4">
                        4.5 (413)
                      </div>
                    </v-row>
                    <div class="my-4 text-subtitle-1">
                      {{ experience.subtitle }}
                    </div>
                    <div>{{experience.description}}</div>
                    <v-container fluid>
                        <v-row align="end">
                          <v-col lg="6" md="12">
                            <v-card-text>
                               <v-data-table
                                  :headers="experience.pricing.headers"
                                  :items="experience.pricing.items"
                                  disable-sort
                                  hide-default-footer
                                >
                                 <template v-slot:footer>
                                 {{experience.pricing.footer}}
                                </template>

                               </v-data-table>
                            </v-card-text>
                          </v-col>
                          <v-col>
                            <v-list dense >
                                <template v-for="(note, index) in experience.notes">
                                   <v-list-item  >
                                     <v-icon left>mdi-check</v-icon>
                                    <v-list-item-content dense class="font-weight-regular">
                                       {{note}}
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                            </v-list>
                          </v-col>
<!--                          <v-divider vertical class="mx-4"></v-divider>-->

                        </v-row>
                        <v-row>
                           <v-expansion-panels>
                              <v-expansion-panel
                                >
                                  <v-expansion-panel-header>
                                    Frequently Asked Questions
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <xfaq></xfaq>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                           </v-expansion-panels>
                        </v-row>
                      </v-container>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="expandbooking = !expandbooking"
                        v-if="!expandbooking"

                      >
<!--                    @click="$vuetify.goTo(target, options)"  -->
                      BOOK NOW
                      </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-card-text>
                      <v-expand-transition>
                        <v-container>
                          <v-row justify="center">
                            <v-col md="8">
                              <xbooking :expandbooking="expandbooking" :car="car" :experience="experience" ></xbooking>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expand-transition>
                    </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import faq from '@/views/FAQ.vue'
import settings from '@/assets/main.js'
import booking from '@/views/Booking.vue'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
export default {
        name: 'experience',
        mixins: [],
        components: {
          xbooking: booking,
          xfaq: faq,
        },
        props: {
          car: Object,
      },
        data () {
          return {
            dialog: false,
            tab: null,
            expandbooking: false,
            type: 'number',
            number: 9999,
            selector: '#first',
            selections: ['#first', '#second', '#third'],
            selected: 'Button',
            elements: ['Button', 'Radio group'],
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
            easings: Object.keys(easings),
          }
        },
        created() {
        },
        computed: {
            target () {
              const value = this[this.type]
              if (!isNaN(value)) return Number(value)
              else return value
            },
            options () {
              return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
              }
            },
            element () {
              if (this.selected === 'Button') return this.$refs.button
              else if (this.selected === 'Radio group') return this.$refs.scrolltarget
              else return null
            },
    },
        watch: {
        },
        methods: {
             get_details () {
               this.dialog = true
            },
        }
    };
</script>