
const cars = [
       {
         flex: 6,
         dialog: false,
         name: 'Caterham',
         owner: 'David',
         picture: "https://elthas.blob.core.windows.net/daganipublic/caterham.jpg",
         events: [
                'Francorchamps (Sunday 1 May 2022)',
                'Zandvoort (Thursday 05 May 2022)',
                'Mettet (Thursday 24 May 2022)',
                'Mettet (Thursday 23 June 2022)',
                'Zolder (Wednesday 20 July 2022)',
                'Mettet (23 August 2022)',
                'Mettet (27 September 2022)',
                'Francorchamps (03 October 2022)',
         ],
         experiences: [
                
            {
                title: 'Drive a Caterham',
                subtitle: " “Adding power makes you faster on the straights; subtracting weight makes you faster everywhere” - Colin Chapman",
                description: "Hire the awesome 180bhp Caterham Superlight R300! Enjoy the go-kart handling and overcome the challenge of mastering one of the best lightweight sports daganipublic ever built. With a 2.0 engine screaming in front of your ears, this is pure driving heaven. The instant response of the steering, coupled with a sprint performance of 0-100 in  just 4.5 seconds will have you hooked. With the Caterham 7 driving experience, you'll receive the rush of track driving, accompanied by an instructor every step of the way. Their job isn't just to keep you safe, they'll be pointing out the racing line and showing you where the apexes are. As well as telling you when to brake and when to really put your foot down. The car is exclusive to you unless you wish to bring an additional driver.",
                notes: [
                    'No prior experience necessary',
                    'Brief track theory: flags and overtaking',
                    'Learn line and adhesion limits',
                    'Four 20 minute track sessions in a Caterham R300',
                    'Drivers must be 18+ with valid driver licence ',
                    'Coaching possible in French, Dutch or English'
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                      ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                         {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€150',              exclusive: '€250'},
                         {what: 'TOTAL',                share: '€650-700',        exclusive: '€1000-1100'},
                    ]
                }
            },


            {
                title: 'Caterham Passenger Experience',
                subtitle: 'Race 15min with a professional driver in a real race car!',
                description: "This experience in the legendary Lotus 7 is a blast! Descendants of the legendary Lotus 7 developed by Colin Chapman, the Caterham 7 is the ultimate kit car and it's also a whole lot of fun. The Caterham is powered by a 2l engine with 180BHP in this super lightweight (650kg) race car.  We'll ease you in gently for a demo with a professional racing driver, once you feel comfortable the speed is picked up to provide you with the ultimate adrenaline rush! ",
                notes: [
                    "Minimum age required 16",
                    "Max height 1m95, Max weight 100kg (limited space available)",
                    "Duration at least 1 hour, safety briefing, sign in, strap in, photos ",
                    "Group arrangements possible on demand"
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: '15min Passenger experience', value: 'share' },
                      ,
                    ],

                    items: [
                         {share: '€50' },

                    ]
                }
            },
         ]
        },

        {
          flex: 6,
          dialog: false,
          name: 'Radical SR1 Blue',
          owner: 'Gael',
          picture: "https://elthas.blob.core.windows.net/daganipublic/RadicalSR1.png",
                events: [
               'Francorchamps (Sunday 1 May 2022)',
                'Zandvoort (Thursday 05 May 2022)',
                'Mettet (Thursday 24 May 2022)',
                'Mettet (Thursday 23 June 2022)',
                'Zolder (Wednesday 20 July 2022)',
                'Mettet (23 August 2022)',
                'Mettet (27 September 2022)',
                'Francorchamps (03 October 2022)',
         ],
          experiences: [
            {
                title: 'Rookie',
                subtitle: '“In racing there are always things you can learn, every single day. There is always space for improvement.” –  Lewis Hamilton',
                description: 'This half day experience is designed to give you a taste of what it\'s like to be a race car driver while enjoying the thrill of driving a Radical SR1 race car.  ' +
                    'You get to suit up like a pro with full-face helmet and racing suit, and learn the fundamentals of driving a race car.  ' +
                    'You start the day learning the basics of driving on a track.  You\'re then off to the track with the instructor as passenger to experience corner lines and braking points; ' +
                    'After a familiarization, you drive on your own the track in an SR1.',
                notes: [
                    'No prior experience necessary',
                    'Track theory flags and overtaking',
                    'Learn line and adhesion limits',
                    'Four 20 minute track sessions in a Radical SR1',
                    'Drivers must be 18+ with valid driver licence ',
                    'Coaching possible in French, Dutch or English'
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                         {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€150',              exclusive: '€250'},
                         {what: "TOTAL",                share: '€650-700',        exclusive: '€1000-1100'},
                    ]
                }
            },


              {
                title: 'Advanced',
                subtitle: '“It’s hard to drive at the limit, but it’s even harder to know where the limits are.” – Stirling Moss',
                description: "In this half day experience the driver will gain a comprehensive insight into professional race car driving. We provide advanced driving techniques and data analysis after every session, which ultimately results in driving the Radical to its limits.  You start the day with a short familiarisation with track rules and SR1 basics.  You're then off to the track following the instructor with a car in front of you learning close racing, drafting and anticipation of braking points; followed by you drive on your own the track in an SR1. After the session, your lap times corner speed and braking points will be discussed based on RaceStudio3.",
                notes: [
                    "Basic experience in an SR1 required",
                    "Data analysis after every session",
                    "For advanced drivers seeking more speed",
                    "Four 20 minute track sessions in a Radical SR1",
                    "Drivers must be 18+ with valid driver licence",
                    "Coaching possible in French, Dutch or English"
                ],
                pricing: {
                     headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                         {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€100',              exclusive: '€200'},
                         {what: 'Total',                share: '€600-650',        exclusive: '€950-1050'},
                    ]
                }
            },



                 {
                title: 'Master',
                subtitle: '“Just leave me alone, I know what to do.” – Kimi Räikkönen',
                description: "The master experience is specifically geared for current licenced racers and drivers with lots of track experience. This is an Arrive and Drive package, the driver feels comfortable with only brief guidance on the SR1, is already familiar with track rules and has been driving an SR1 before. Including 2 hours of free driving to spend in the morning or afternoon session at the drivers convenience.",
                notes: [
                    "Advanced experience in an SR1 required",
                    "Arrive and drive - we take care of it all",
                    "Discuss preferred setup: tyres, rollbars, engine",
                    "6 sessions of 20min free driving",
                    "Drivers must be 18+ with valid driver licence",
                    "Coaching as optional extra on demand"
                ],
                pricing: {
                     headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                          {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€50',              exclusive: '€100'},
                         {what: 'Total',                share: '€550-600',        exclusive: '€850-950'},
                    ]
                }
            },



            {
                title: 'Passenger',
                subtitle: 'Race 15min with a professional driver in a real race car!',
                description: "Get strapped into the passenger seat one of the most exciting entry level race daganipublic on the planet – the all new Radical SR1 - capable of 0-100kph in 3.6 seconds. Powered Suzuki Hayabusa engine, mated to a six-speed sequential gearbox, this racing machine leaves its competitors on the starting grid. With exceptional cornering capability, the aerodynamics mean the SR1 gets pushed down into the asphalt at high speeds, and can pull 2.3g through fast bends - more than twice the handling capability of the Lamborghini Aventador. ",
                notes: [
                    "Minimum age required 16",
                    "Max height 1m95, Max weight 100kg (limited space available)",
                    "Duration at least 1 hour, safety briefing, sign in, strap in, photos ",
                    "Group arrangements possible on demand"
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: '15min Passenger experience', value: 'share' },
                    ],

                    items: [
                         {share: '€60' },
                    ]
                }
            },

         ]
        },
        {
          flex: 6,
          dialog: false,
          name: 'Radical SR1 Gulf',
          owner: 'David',
          picture: "https://elthas.blob.core.windows.net/daganipublic/SR1David.jpg",
              events: [
                 'Francorchamps (Sunday 1 May 2022)',
                 'Zandvoort (Thursday 05 May 2022)',
                'Mettet (Thursday 24 May 2022)',
                'Mettet (Thursday 23 June 2022)',
                'Zolder (Wednesday 20 July 2022)',
                'Mettet (23 August 2022)',
                'Mettet (27 September 2022)',
                'Francorchamps (03 October 2022)',
         ],
          experiences: [
            {
                title: 'Rookie',
                subtitle: '“In racing there are always things you can learn, every single day. There is always space for improvement.” –  Lewis Hamilton',
                description: 'This half day experience is designed to give you a taste of what it\'s like to be a race car driver while enjoying the thrill of driving a Radical SR1 race car.  ' +
                    'You get to suit up like a pro with full-face helmet and racing suit, and learn the fundamentals of driving a race car.  ' +
                    'You start the day learning the basics of driving on a track.  You\'re then off to the track with the instructor as passenger to experience corner lines and braking points; ' +
                    'After a familiarization, you drive on your own the track in an SR1.',
                notes: [
                    'No prior experience necessary',
                    'Track theory flags and overtaking',
                    'Learn line and adhesion limits',
                    'Four 20 minute track sessions in a Radical SR1',
                    'Drivers must be 18+ with valid driver licence ',
                    'Coaching possible in French, Dutch or English'
                ],
                pricing: {
                     headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                           {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€150',              exclusive: '€250'},
                         {what: 'Total',                share: '€650-700',        exclusive: '€1000-1100'},
                    ]
                }
            },


              {
                title: 'Advanced',
                subtitle: '“It’s hard to drive at the limit, but it’s even harder to know where the limits are.” – Stirling Moss',
                description: "In this half day experience the driver will gain a comprehensive insight into professional race car driving. We provide advanced driving techniques and data analysis after every session, which ultimately results in driving the Radical to its limits.  You start the day with a short familiarisation with track rules and SR1 basics.  You're then off to the track following the instructor with a car in front of you learning close racing, drafting and anticipation of braking points; followed by you drive on your own the track in an SR1. After the session, your lap times corner speed and braking points will be discussed based on RaceStudio3.",
                notes: [
                    "Basic experience in an SR1 required",
                    "Data analysis after every session",
                    "For advanced drivers seeking more speed",
                    "Four 20 minute track sessions in a Radical SR1",
                    "Drivers must be 18+ with valid driver licence",
                    "Coaching possible in French, Dutch or English"
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                       {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€100',              exclusive: '€200'},
                         {what: 'Total',                share: '€600-650',        exclusive: '€950-1050'},
                    ]
                }
            },



                 {
                title: 'Master',
                subtitle: '“Just leave me alone, I know what to do.” – Kimi Räikkönen',
                description: "The master experience is specifically geared for current licenced racers and drivers with lots of track experience. This is an Arrive and Drive package, the driver feels comfortable with only brief guidance on the SR1, is already familiar with track rules and has been driving an SR1 before. Including 2 hours of free driving to spend in the morning or afternoon session at the drivers convenience.",
                notes: [
                    "Advanced experience in an SR1 required",
                    "Arrive and drive - we take care of it all",
                    "Discuss preferred setup: tyres, rollbars, engine",
                    "6 sessions of 20min free driving",
                    "Drivers must be 18+ with valid driver licence",
                    "Coaching as optional extra on demand"
                ],
                pricing: {
                     headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                         {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€50',              exclusive: '€100'},
                         {what: 'Total',                share: '€550-600',        exclusive: '€850-950'},
                    ]
                }
            },



            {
                title: 'Passenger',
                subtitle: 'Race 15min with a professional driver in a real race car!',
                description: "Get strapped into the passenger seat one of the most exciting entry level race daganipublic on the planet – the all new Radical SR1 - capable of 0-100kph in 3.6 seconds. Powered Suzuki Hayabusa engine, mated to a six-speed sequential gearbox, this racing machine leaves its competitors on the starting grid. With exceptional cornering capability, the aerodynamics mean the SR1 gets pushed down into the asphalt at high speeds, and can pull 2.3g through fast bends - more than twice the handling capability of the Lamborghini Aventador. ",
                notes: [
                    "Minimum age required 16",
                    "Max height 1m95, Max weight 100kg (limited space available)",
                    "Duration at least 1 hour, safety briefing, sign in, strap in, photos ",
                    "Group arrangements possible on demand"
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: '15min Passenger experience', value: 'share' },
                    ],

                    items: [
                         {share: '€60' },

                    ]
                }
            },

         ]
        },
        {
          flex: 6,
          dialog: false,
          name: 'Radical SR3',
          owner: 'Gael',
          picture: "https://elthas.blob.core.windows.net/daganipublic/SR3Foto.png",
                events: [
                 'Francorchamps (Sunday 1 May 2022)',
                'Zandvoort (Thursday 05 May 2022)',
                'Mettet (Thursday 24 May 2022)',
                'Mettet (Thursday 23 June 2022)',
                'Zolder (Wednesday 20 July 2022)',
                'Mettet (23 August 2022)',
                'Mettet (27 September 2022)',
                'Francorchamps (03 October 2022)',
         ],
          experiences: [
              {
                title: 'Advanced',
                subtitle: '“Straight roads are for fast cars, turns are for fast drivers.”  – Colin McRae',
                description: "This half day experience is meant for the drivers who gained track experience true our SR1 program or have been driving an SR3 on track before. The SR3 is a seriously fast racing machine with an enormous downforce potential, during the day  the driver will gain experiencing how to drive the car on its limits. You start the day with a short familiarisation with track rules and SR3 basics.  You're then off to the track with the instructor as passenger, providing tips on gears, brake points and corner lines; followed by you drive on your own the track in an SR3. After the session, your lap times corner speed and braking points will be discussed based on RaceStudio3.",
                notes: [
                    "Experience in an SR1 or SR3 prerequisite",
                    "Data analysis after every session",
                    "Full slicks ",
                    "Four 20 minute track sessions in a Radical SR3",
                    "Drivers must be 18+ with valid driver licence",
                    "Coaching possible in French, Dutch or English"
                ],
                pricing: {
                     headers: [
                        { text: '', value: 'what' },
                        { text: 'Share with a friend *', value: 'share' },
                        { text: 'Exclusive', value: 'exclusive' },
                    ],
                    footer: "* Prices and experiences are per person, each will have four 20min sessions",
                    options: [
                        'Exclusive',
                        'Share with a friend'
                    ],
                    items: [
                        {what: 'Rent',                 share: '€350',              exclusive: '€500'},
                         {what: 'Track Day Fee',        share: '€150-200',          exclusive: '€250-350'},
                         {what: 'Coaching',             share: '€100',              exclusive: '€200'},
                         {what: 'Total',                share: '€600-650',        exclusive: '€950-1050'},
                    ]
                }
            },
                 
                 
                 
            {
                title: 'Passenger',
                subtitle: 'Race 15min with a professional driver in a real race car!',
                description: "Get strapped in the passenger seat of what is often the fastest car on circuit during track days - the Radical SR3 - for a blistering high speed ride sitting inches from the ground you will experience the g-force of the Radicals unbelievable cornering, braking and acceleration as your Instructor drives the car to its limits around the track. This is the ultimate adrenaline rush! ",
                notes: [
                    "Minimum age required 16",
                    "Max height 2m15, Max weight 115kg (limited space available)",                    
                    "Duration at least 1 hour, safety briefing, sign in, strap in, photos ",
                    "Group arrangements possible on demand"
                ],
                pricing: {
                    headers: [
                        { text: '', value: 'what' },
                        { text: '15min Passenger experience', value: 'share' },
                    ],
               
                    items: [
                         {share: '€60' },
                   
                    ]
                }
            },
                        
         ]
        },

       
       
   
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
]

export default cars;
