<template>

<v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Car and Event
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Personal Info
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Booking
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">

        <v-card flat>
              <v-img
                height="250"
                :src="car.picture"
              ></v-img>
              <v-card-title class="align-center">{{car.name}} - {{experience.title}}</v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="form"
                  class="pa-4 pt-6"
                >
                <v-row justify="left">
                  <v-col cols="12">
                    <v-switch
                        v-model="insurance"
                        :label="`Incl. Insurance`"
                      ></v-switch>
        <!--            <v-select-->
        <!--                  :items="experiences"-->
        <!--                  v-model="experience"-->
        <!--                  label="Experience"-->
        <!--                  dense-->
        <!--                  required-->
        <!--                  :rules="[v => !!v || 'Obligatory field']"-->
        <!--              ></v-select>-->
                  </v-col>
                  <v-col md="6">
                      <v-select
                          :items="car.events"
                          v-model="event"
                          label="Events"
                          dense
                          required
                          :rules="[v => !!v || 'Obligatory field']"
                      ></v-select>
                   </v-col>
                  <v-col md="6">
                      <v-select
                          :items="experience.pricing.options"
                          v-model="experience_option"
                          label="Option"
                          dense
                          required
                          :rules="[v => !!v || 'Obligatory field']"
                      ></v-select>
                   </v-col>
                </v-row >
                </v-form>
              </v-card-text>
            </v-card>

        <v-btn
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>

      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card flat >

          <v-card-text>
              <v-form
                  ref="form"
                  v-model="form"
                  class="pa-4 pt-6"
                >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                      v-model="company"
                      filled
                      color="deep-purple"
                      label="Company name (optional)"
                      clearable
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="4" xs="12">
                   <v-text-field
                    v-model="firstname"
                    filled
                    color="deep-purple"
                    label="First Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="8"xs="12" >
                  <v-text-field
                  v-model="lastname"
                  filled
                  color="deep-purple"
                  label="Last Name"
                  required
                ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="phone"
                    filled
                    color="deep-purple"
                    label="Phone number"
                    required
                    clearable
                    ref="scrolltarget"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                   <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                    :rules="[rules.email]"
                    filled
                    color="deep-purple"
                    label="Email address"
                    type="email"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                   <v-text-field
                  prepend-inner-icon="mdi-map-marker"
                  filled
                  v-model="address"
                  color="deep-purple"
                  label="Address"
                  required
                  clearable
                ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                    <v-textarea
                      v-model="remarks"
                      auto-grow
                      filled
                      color="deep-purple"
                      label="Remarks"
                      rows="5"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-checkbox
                  v-model="agreement"
                  :rules="[rules.required]"
                  color="deep-purple"
                >
                  <template v-slot:label>
                    I agree to the&nbsp;
                    <a
                      href="#"
                      @click.stop.prevent="dialog = true"
                    >Terms of Service</a>
                    &nbsp;

                  </template>
                </v-checkbox>
              </v-row>
              </v-form>
            <v-dialog
      v-model="dialog"
      absolute
      max-width="800"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-3">
          Terms and Conditions
        </v-card-title>
        <v-card-text>
          <xtermsandconditions></xtermsandconditions>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="agreement = false, dialog = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="deep-purple accent-4"
            @click="agreement = true, dialog = false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          </v-card-text>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 3"
        >
          Continue
        </v-btn>

        <v-btn text
        @click="e1 = 1"
        >
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          flat
        >
          <v-card-text>
            <v-row justify="center">
              <v-btn
                :disabled="!form"
                :loading="isLoading"
                class="white--text"
                color="primary"
                depressed
                @click="book"
                v-show="!booking_request"
              >
                Request booking
              </v-btn>

              <v-progress-circular
                :indeterminate="booking_request"
                v-show="booking_request"
                color="primary"
              ></v-progress-circular>
            </v-row>
            <v-spacer></v-spacer>
            <v-row justify="center">

              <v-alert
                outlined
                type="success"
                text
                :value="booking_requested"
              >
                A booking email has been send to your email adress and to DaGaNi Racing, you can expect a reply from us shortly. Thank you!
              </v-alert>

            </v-row>
          </v-card-text>
        </v-card>
        <v-btn text
         @click="e1 = 2"
        >
          Back
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

</template>
<script>
import axios from 'axios'
import termsandconditions from '@/views/TermsAndConditions.vue'
import events from '@/assets/events.js'
  export default {
    name: 'booking',
    mixins: [],
    components: {
      xtermsandconditions: termsandconditions,
    },
    props: {
      // expandbooking: Boolean,
      car: String,
      experience: String,
      experiences: Array,
    },
    data: () => ({
      maildialog: false,
      taq_dialog: false,
      booking_requested: false,
      booking_request: false,
      e1: 1,
      events: events,
      event: '',
      experience_option: '',
      insurance: false,
      agreement: false,
      date: new Date().toISOString().substr(0,10),
      company: '',
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      address: '',
      remarks: '',
      dialog: false,
      form: false,
      isLoading: false,
      password: undefined,
      rules: {
        email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
        password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          'Password must contain an upper case letter, a numeric character, and a special character',
        required: v => !!v || 'This field is required',
      },
    }),
    computed: {
    },

    methods: {
      book() {
          this.booking_request = true
          this.booking_requested = false
          axios.post('/email_to_info_dagani_racing',
            {

              'car': this.car.name,
              'experience': this.experience.title,
              'event': this.event,
              'experience_option': this.experience_option,
              'insurance': this.insurance,
              'phone': this.phone,
              'firstname': this.firstname,
              'lastname': this.lastname,
              'email': this.email,
              'address': this.address,
              'company': this.company,
              'remarks': this.remarks,
            },
              {
                params: {
                  sender: 'boink',
                }
              })
            .then((resp) => {
                console.log(resp)
                this.booking_request = false
                this.booking_requested = true

            })
            .catch((err) => {
                console.log(err)
            },
            );
          return 200
      },
    },
  }
</script>
