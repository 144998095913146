import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VueYouTubeEmbed from 'vue-youtube-embed'
import axios from "axios"

Vue.config.productionTip = false

require('@/assets/styles/main.css')

axios.defaults.baseURL =  process.env.VUE_APP_LUX_BASE_URL
Vue.use(VueYouTubeEmbed)

new Vue({
  router,
  store,
  vuetify,
  VueYouTubeEmbed,
  render: function (h) { return h(App) }
}).$mount('#app')
