<template>
<!--  <div>src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"-->
  <div>
     <v-parallax
         :src="settings.parallax.background"
         dark="false"
         :height="settings.parallax.parallax_height"
     >
       <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="text-center"
            md="5"
          >
            <v-img
            src="https://elthas.blob.core.windows.net/daganipublic/logo-white.png"
            height="100"
            contain
         ></v-img><br>
            <p :class="this.settings.parallax.title.class" :style="this.settings.parallax.title.color"> {{this.settings.parallax.title.name}}</p>
            <br>
            <p :class="this.settings.parallax.caption.class" :style="this.settings.parallax.caption.color">{{this.settings.parallax.caption.text}}</p>
          </v-col>
        </v-row>
       <v-row justify="center">
          <v-col xs="12" md="8">
            <v-card
              color="transparent"
              flat
            >
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                    v-for="car in cars"
                    :key="car.name"
                    :md="car.flex"
                    xs="12"
                  >
<!--                    :cols="car.flex"-->
                    <v-card>
                         <v-img
                          :src="car.picture"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="300px"
                        >
                        <v-card-title v-text="car.name"></v-card-title>
                      </v-img>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                         <xexperience :car="car"></xexperience>
                   </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col
                    md="8"
                    xs="12"
                    >
                    <v-card>
                      <v-img
                        :src="settings.teambuilding.picture"
                        class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="300px"
                      >
                        <v-card-title>{{this.settings.teambuilding.title}}</v-card-title>
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="teambuilding_dialog" max-width="1200px" ref="button">
                          <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark v-on="on" >Details</v-btn>
                          </template>
                          <v-card flat>
                              <v-img
                                height="250"
                                :src="settings.teambuilding.picture"
                              ></v-img>
                            <v-card-text>
                               <template v-for="q in settings.teambuilding.terms">
                                <p class="font-weight-black">{{q.question}}</p>
                                <template v-for="a in q.answers">
                                  {{a}}
                                  <br><br>
                                </template>
                              </template>
                              </v-card-text>
                          </v-card>
                      </v-dialog>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>

       <v-row justify="center">
          <youtube
               :video-id="settings.youtubeVideoid"
               :player-vars="{start: 0, autoplay: 0}"
           ></youtube>
        </v-row>
       <v-row justify="center">
         <v-toolbar
            color="transparent"
            flat
         >

           <v-spacer></v-spacer>
            <v-list
                  dense
                  rounded
                >
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>info@dagani-racing.be</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item >
                <v-list-item-action>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle>+32 477 56 47 44</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
        </v-list>
            <v-spacer></v-spacer>
           <v-dialog
              v-model="gallery_dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Photo Gallery
                </v-btn>
              </template>
              <v-card>
                <v-toolbar
                  dark
                  color="blue"
                >
                  <v-btn
                    icon
                    dark
                    @click="gallery_dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>DaGaNi Racing Gallery</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
<!--                                  <v-btn-->
<!--                                    dark-->
<!--                                    text-->
<!--                                    @click="dialog = false"-->
<!--                                  >-->
<!--                                    Save-->
<!--                                  </v-btn>-->
                  </v-toolbar-items>
                </v-toolbar>
                <xgallery></xgallery>
              </v-card>
            </v-dialog>
           <v-spacer></v-spacer>
           <v-dialog
                            v-model="faq_dialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="blue"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                Frequently Asked Questions
                              </v-btn>
                            </template>
                            <v-card>
                              <v-toolbar
                                dark
                                color="blue"
                              >
                                <v-btn
                                  icon
                                  dark
                                  @click="faq_dialog = false"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-toolbar-title>Frequently Asked Questions</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
<!--                                  <v-btn-->
<!--                                    dark-->
<!--                                    text-->
<!--                                    @click="dialog = false"-->
<!--                                  >-->
<!--                                    Save-->
<!--                                  </v-btn>-->
                                </v-toolbar-items>
                              </v-toolbar>
                              <xfaq></xfaq>
                            </v-card>
                          </v-dialog>
           <v-spacer></v-spacer>
           <v-dialog
                    v-model="taq_dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Terms and Conditions
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar
                        dark
                        color="blue"
                      >
                        <v-btn
                          icon
                          dark
                          @click="taq_dialog = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title>Terms and Conditions</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
  <!--                                  <v-btn-->
  <!--                                    dark-->
  <!--                                    text-->
  <!--                                    @click="dialog = false"-->
  <!--                                  >-->
  <!--                                    Save-->
  <!--                                  </v-btn>-->
                        </v-toolbar-items>
                      </v-toolbar>
                      <xtermsandconditions></xtermsandconditions>
                    </v-card>
                  </v-dialog>
           <v-spacer></v-spacer>
         </v-toolbar>
       </v-row>
       <v-spacer></v-spacer>
       <v-spacer></v-spacer>
       <v-spacer></v-spacer>
     </v-parallax>

 </div>

</template>

<script>
import settings from '@/assets/main.js'
import cars from '@/assets/cars.js'
import experience from '@/views/Experience.vue'
import FAQ from '@/views/FAQ.vue'
import termsandconditions from '@/views/TermsAndConditions.vue'
import gallery from '@/views/Gallery.vue'


export default {
  bodyClass: "landing-page",
  name: 'app',
  components: {
      xexperience: experience,
      xfaq: FAQ,
      xgallery: gallery,
      xtermsandconditions: termsandconditions,
  },
  props: {
    // background: {
    //   type: String,
    //   default: require("@/assets/daganipublic/background.jpg")
    // },
  },
  data() {
    return {
      settings: settings,
      cars: cars,
      videoId: 'OH3yX_ina-U',
      teambuilding_dialog: false,
      gallery_dialog: false,
      faq_dialog: false,
      taq_dialog: false,
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>


<style scoped src="@/assets/styles/main.css">
</style>