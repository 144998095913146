<template>
  <div>
      <v-row justify="center" >
          <v-col xs="12" md="10">
            <v-card
              color="transparent"
              flat
            >
              <v-container fluid>
                <v-row >
                  <v-col
                    v-for="g in gallery"
                    :key="g.picture"
                    :md="g.flex"
                    xs="12"
                  >
                   <v-img
                    :src="g.picture"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  >
                  </v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
 </div>
</template>

<script>
import gallery from '@/assets/gallery.js'


export default {
  bodyClass: "landing-page",
  name: 'app',
  components: {
  },
  props: {
    // background: {
    //   type: String,
    //   default: require("@/assets/daganipublic/background.jpg")
    // },
  },
  data() {
    return {
      gallery: gallery,
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>


<style scoped src="@/assets/styles/main.css">
</style>