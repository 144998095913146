<template>
  <div>
  <template v-for="q in termsandconditions.terms">
    <p class="font-weight-black">{{q.question}}</p>
    {{q.answer}}
    <br><br>
  </template>
    </div>
</template>
<script>
import settings from '@/assets/main.js'

export default {
  name: 'TAQ',

  data: () => ({
    termsandconditions: settings.termsandconditions,
  }),
};
</script>